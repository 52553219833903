import React from "react";
import Providers from "./Providers";
import Speak from "./../../components/Speak";
const Index = ({location}) => {
  return (
    <div>
      <Providers />
      <Speak  location={location}/>
    </div>
  );
};

export default Index;
