import React from "react";
import Index from "../views/Providers/Index";
import Layout from "../components/Layout";
const providers = ({ location }) => {
  return (
    <div>
      {" "}
      <Layout location={location}>
        <Index location={location} />
      </Layout>
    </div>
  );
};

export default providers;
