import React from "react";
import { Link } from "gatsby";
import provider from "../../images/Neutral Boho Minimal Moodboard Aesthetic Feminine Photo Collage (10 × 5 cm) (Photo Collage).png";

const Providers = () => {
  return (
    <div
      id="providers"
      className="w-full px-6 pt-24 m-auto max-w-7xl md:px-10 md:pt-14 pb-28"
    >
      <div className="flex flex-col items-center justify-center w-full">
        <h1 className="mt-12 mb-12 text-2xl font-bold text-center uppercase lg:mt-0 md:text-4xl md:mb-8 fonbo">
          Providers
        </h1>

        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          <div className="order-last lg:order-none">
            <p>
              Every provider we offer goes through an extensive vetting process.
              We assess their products and legal agreements to ensure the best
              possible service and support. Aside from lowest pricing
              guarantees, clients also benefit from our direct escalation routes
              to executive-level management at each provider, so all your
              support needs are resolved quickly. We are your security blanket
              to ensure a seamless transition and successful experience with new
              technologies.
              <br />
              <br />
              Our goal is to seamlessly transition dealers to technology systems
              that provide unprecedented visibility into digital client
              interactions. We reduce costs by improving marketing and sales
              strategies, as well as streamlining dealership operations and
              processes.
              <br />
              <br />
              DealerSys custom designs the perfect solutions by evaluating
              hundreds of providers' products and services, tailoring them to
              your specific needs. We take the stress out of managing a wide
              network of provider relationships, and function as your security
              blanket to ensure a seamless transition and successful experience
              with new technologies.
              {/* <br />
              We are your security blanket to ensure a seamless transition and
              successful experience with new technologies. */}
            </p>

            <div className="mt-10">
              <Link to="/contact-us">
                <span className="font-medium text-sm bg-black text-white px-4 py-3.5 rounded-lg transition-all hover:shadow-lg">
                  Help me choose the right provider
                </span>
              </Link>
            </div>
          </div>

          <div>
            <img className="w-full h-full " src={provider} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providers;
